import React from "react";
import SEO from "../../components/seo";
import Blog from "../../components/Blog";
import Par from "../../components/Par";
import H1 from "../../components/h1";

const title = "Hepatitis A";

// Download link - http://www.fbrennangastro.com.au/downloads/gesahepatitisa.pdf

export default () => (
	<Blog title={title}>
		<SEO
			title={title}
			keywords={[title, "Causes of hep A", "Hep A", "liver virus", "hepatitis immunisation", "hepatitis vaccine"]}
			type="article"
			description="Hepatitis type A information - how the liver virus presents itself, immunisation options for children and adults"
		/>
		<H1>{title}</H1>
		<Par>
			Hepatitis A is one of several different hepatitis viruses that can cause liver infections and damage. Anyone can
			be infected with hepatitis A if they come in direct contact with food, drinks or objects contaminated by the
			faeces of an infected person.
		</Par>
		<Par>
			Hepatitis A is a viral disease that affects the liver. Children younger than age 6 often have no symptoms. Older
			children and adults often get flu-like symptoms, abdominal pain, diarrhoea and may develop jaundice.
		</Par>
		<Par>
			Hepatitis A usually gets better in a few weeks without treatment. One infection of hepatitis A will give you
			lifelong protection.
		</Par>
		<Par>
			Immunisation against hepatitis A includes a course of injections over six to 12 months. Hepatitis A immunisation
			is a good idea for people travelling to places where the disease is common, or to places where hygiene standards
			are poor.
		</Par>
		<Par>
			The vaccine may also be recommended for childcare workers, men who have sex with men, people with liver problems,
			food handlers and injecting drug users.
		</Par>
	</Blog>
);
